.timeline {
  display: flex;
  flex-direction: column;
}

.timeline .timeline-header {
  max-width: 8em;
  padding: 5px 0px 5px 0;
  border-radius: 5px;
  background-color: #575757;
  color: #dbdbdb;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
}

.timeline .timeline-item {
  display: flex;
  display: -webkit-flex;
  position: relative;
  margin-left: 2em;
  padding-bottom: 2em;
}

.timeline .timeline-item .timeline-marker.is-image {
  background: #dbdbdb;
  border: 1px solid #dbdbdb;
  border-radius: 100%;
  display: flex;
  overflow: hidden;
}

.timeline .timeline-item .timeline-marker.is-image.is-46x46 {
  height: 46px;
  width: 46px;
}

.timeline.is-centered .timeline-header {
  display: flex;
  width: 100%;
  align-self: center;
}

.timeline.is-centered .timeline-item .timeline-content {
  padding: 0em 0em 1em 2em;
}

.timeline.is-centered .timeline-item:nth-of-type(2n + 1) .timeline-content {
  padding: 1em 2em 0 0;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-basis: 100%;
}

.timeline.is-centered .timeline-item {
  width: 50%;
  align-self: flex-end;
  flex-direction: row;
}

.timeline.is-centered .timeline-item:nth-of-type(2n + 1) {
  align-self: flex-start;
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: 2em;
}

.timeline .timeline-item::before {
  content: "";
  background-color: #831414;
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  left: -0.5px;
  top: 0;
}

.timeline.is-centered .timeline-item:nth-of-type(2n + 1)::before {
  right: -0.5px;
  left: auto;
}

.timeline .timeline-item .timeline-marker {
  position: absolute;
  display: block;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 1.8rem;

  background: #dbdbdb;
  border: 1px solid #dbdbdb;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  z-index: 2;
}

.timeline.is-centered .timeline-item:nth-of-type(2n + 1) .timeline-marker {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

@media screen and (max-width: 431px) {
  .timeline.is-centered .timeline-header {
    align-self: normal;
  }
  .timeline.is-centered .timeline-item:nth-of-type(2n + 1) .timeline-marker {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .timeline.is-centered .timeline-item:nth-of-type(2n + 1)::before {
    left: -0.5px;
    right: 0;
  }
  .timeline.is-centered .timeline-item {
    width: 90%;
    padding-right: 40px;
    align-self: flex-start;
  }
  .timeline.is-centered .timeline-item:nth-of-type(2n + 1) {
    align-self: flex-start;
    flex-direction: row;
    margin-right: 0em;
    margin-left: 2em;
  }
  .timeline.is-centered .timeline-item:nth-of-type(2n + 1) .timeline-content {
    padding: 0em 0em 1em 2em;
    text-align: left;
    display: block;
  }
}

.timeline-item .item-header {
  text-transform: uppercase;
  display: block;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.timeline-item .item-title {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
  margin-bottom: 0.4rem;
}

.timeline-item .item-subtitle {
  color: #434343;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
}

.timeline-item .item-content {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 24px;
  max-width: 410px;
}
