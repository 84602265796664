@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities{
  .flex-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .flex-evenly{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .flex-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .grid-2-colums{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: center;
  }

  .flex-auto-columns{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
